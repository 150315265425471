
import { defineComponent, onMounted, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { Apollo, Notify } from '@/core/services';
import { GET_CUSTOMER, SEARCH_CUSTOMER, LOGIN_AS } from '../graphql/Queries';
import { DELETE_CUSTOMER } from '../graphql/Mutations';
import Search from '../../../../components/search/Search.vue';
import AdvanceSearch from '../../../../components/search/AdvanceSearch.vue';
import Table from '../../../../components/Table/Table.vue';
import { generateAvatar } from '@/core/helpers/generateAvatar';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { setPageHistory } from '@/core/helpers/toolbar';
import InnerLoader from '../../../../components/InnerLoader.vue';
import { dropdown_handler } from '../../../../core/helpers/dropdownHandler';
import { GET_CUSTOMER_GROUP } from '@/modules/customer/customer-groups/graphql/Queries';

export default defineComponent({
    components: {
        // Search,
        AdvanceSearch,
        Table,
        InnerLoader
    },
    setup() {
        const i18n = useI18n();
        const store = useStore();
        const loader = ref(true);
        const loading = ref(false);
        const pagination = ref({}) as Record<any, any>;
        const permissions = store.getters.getPermissions;
        const customers: any = ref([]);
        const clickHandler = ref() as Record<any, any>;
        const currentPage = ref(0);
        const system_locale = ref();
        const router = useRouter();
        const activeIndex = ref(-1);
        const search_customer_query = ref(false);
        const columnName = ref(null);
        const columnSort = ref(null);
        const customerGroupList = ref([]) as Record<any, any>;
        const filterOption = ref({}) as Record<any, any>;

        filterOption.value = {
            status: true,
            customer_group: true,
            economic_number: true,
            name: true,
            email: true
        };

        const statusList = ref([
            {
                label: 'message.ALL',
                value: 'all'
            },
            {
                label: 'message.ACTIVE',
                value: 1
            },
            {
                label: 'message.INACTIVE',
                value: 0
            }
        ]);
        filterOption.value.status = true;

        const columns = ref([
            {
                label: '',
                key: 'avatar'
            },
            {
                label: 'message.NAME',
                key: 'name',
                sorting: true
            },
            {
                label: 'message.EMAIL',
                key: 'email',
                sorting: true
            },
            {
                label: 'message.ACCOUNT_ECONOMIC_NUMBER',
                key: 'economic_number',
                sorting: true
            },
            {
                label: 'message.CUSTOMER_GROUP',
                key: 'customer_group'
            },
            // {
            // 	label: "message.SITE",
            // 	key: "site",
            // 	width: true,
            // },
            {
                label: 'message.STATUS',
                key: 'status'
            },
            {
                label: 'message.ACTIONS',
                key: 'actions',
                textAlignment: 'center'
            }
        ]);

        const filterObject = ref([
            {
                label: 'message.NAME',
                value: 'name'
            },
            {
                label: 'message.EMAIL',
                value: 'email'
            }
        ]);

        const handleCurrentChange = (val: number) => {
            clickHandler.value(val);
        };

        const dropdownHandler = (index, id) => {
            activeIndex.value = index === id ? null : id;
        };
        const handleClickOutside = event => {
            if (!event.target.closest('.action-btn')) {
                activeIndex.value = -1;
            }
        };

        const getCustomerGroups = () => {
            search_customer_query.value = false;
            // loader.value = true;
            Apollo.watchQuery({
                query: GET_CUSTOMER_GROUP,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'network-only'
            }).subscribe(({ data }) => {
                customerGroupList.value = [];
                data.customer_groups.data.forEach(ele => {
                    customerGroupList.value.push({
                        label: ele.name,
                        value: ele.id
                    });
                });
                // loader.value = false;
                filterOption.value.customer_group = true;
            });
        };

        getCustomerGroups();

        const getCustomers = (page = 1, reload = false, colName = null, type = null) => {
            loader.value = true;
            columnName.value = null;
            columnSort.value = null;
            search_customer_query.value = false;
            Apollo.watchQuery({
                query: GET_CUSTOMER,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                variables: {
                    page: page,
                    limit: 10
                    // col: colName,
                    // type: type == true ? "asc" : "desc",
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                reload == true ? (currentPage.value = 1) : false;
                clickHandler.value = getCustomers;
                customers.value = data.customers.data;
                pagination.value = data.customers;
                loader.value = false;
            });
        };

        const handleEdit = (user: Record<any, any>) => {
            const searchData = store.getters.getSearchData;
            setPageHistory({
                filter: searchData?.data?.select,
                search_key: searchData?.data?.input,
                page_number: pagination.value?.current_page
            });
            router.push({ name: 'edit_customer', params: { uuid: user.uuid } });
        };

        const handleView = (val: Record<any, any>) => {
            const searchData = store.getters.getSearchData;
            setPageHistory({
                filter: searchData?.data?.select,
                search_key: searchData?.data?.input,
                page_number: pagination.value?.current_page
            });
            router.push({
                name: 'customer_detail',
                params: { uuid: val.uuid }
            });
        };

        const handleLoginAs = (customer: Record<any, any>) => {
            loading.value = true;
            const login_as = Apollo.watchQuery({
                query: LOGIN_AS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                variables: {
                    uuid: customer?.user.uuid
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loading.value = false;
                }

                if (data?.login_as != null) window.open(`${process.env.VUE_APP_GEP_SHOP}/auth/attempt-login/${data.login_as}`, '_blank');
                login_as.unsubscribe();
                data = null;
                loading.value = false;
            });
        };

        const searchHandler = (page = 1, reload = false, colName = null, type = null) => {
            if (colName != null) columnName.value = colName;
            if (type != null) columnSort.value = type;
            search_customer_query.value = true;
            const searchData = store.getters.getSearchData;
            loader.value = true;
            Apollo.watchQuery({
                query: SEARCH_CUSTOMER,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                variables: {
                    page: page,
                    limit: 10,
                    filter: JSON.stringify(searchData?.data?.select),
                    search_key: searchData?.data?.input,
                    locale: system_locale.value,
                    col: columnName.value,
                    type: columnSort.value == true ? 'asc' : 'desc'
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }

                clickHandler.value = searchHandler;
                customers.value = data?.search_customers?.data;
                pagination.value = data?.search_customers;
                loader.value = false;
            });
        };

        const handleDelete = (id: Record<any, any>) => {
            store.getters.appInstance.$messageBox
                .confirm(`${i18n.t('message.ARE_YOU_SURE_TO_DELETE_RECORD')}?`, i18n.t('message.INFO'), {
                    confirmButtonText: i18n.t('message.OK'),
                    cancelButtonText: i18n.t('message.CANCEL'),
                    type: 'info'
                })
                .then(async () => {
                    loader.value = true;
                    await Apollo.mutate({
                        mutation: DELETE_CUSTOMER,
                        variables: { id: id },
                        update: (store, { data: { delete_customer } }) => {
                            // const read_data = store.readQuery({
                            // 	query: GET_CUSTOMER,
                            // 	variables: {
                            // 		page: pagination.value.current_page,
                            // 		limit: 10,
                            // 	},
                            // }) as Record<any, any>;
                            // const data = read_data.customers.data.filter((t: any) => t.id !== delete_customer);
                            // store.writeQuery({
                            // 	query: GET_CUSTOMER,
                            // 	data: {
                            // 		customers: {
                            // 			...read_data.customers,
                            // 			data: [...data],
                            // 		},
                            // 	},
                            // 	variables: {
                            // 		page: pagination.value.current_page,
                            // 		limit: 10,
                            // 	},
                            // });
                            searchHandler(pagination.value?.current_page, true, columnName.value, columnSort.value);
                            loader.value = false;
                        }
                    });
                    Notify.success(i18n.t('message.RECORD_DELETED_SUCCESSFULLY'));
                })
                .catch(() => {
                    loader.value = false;
                });
        };

        watchEffect(() => {
            system_locale.value = i18n.fallbackLocale.value;
        });

        const exportFile = data => {
            const searchData = store.getters.getSearchData;
            loading.value = true;
            const sub = Apollo.watchQuery({
                query: SEARCH_CUSTOMER,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                variables: {
                    page: 0,
                    limit: 1,
                    filter: searchData?.data?.select ? JSON.stringify(searchData.data.select) : '',
                    search_key: searchData?.data?.input ? searchData?.data?.input : '',
                    locale: system_locale.value,
                    types: data?.type,
                    date_from: data?.dateFrom,
                    date_to: data?.dateTo
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loading.value = false;
                } else if (data) {
                    window.open(data?.search_customers?.data[0]?.path, '_blank');
                    loading.value = false;
                }
                loading.value = false;
                sub.unsubscribe();
            });
        };

        onMounted(() => {
            const pageHistory = store.getters.getPageHistory;
            document.addEventListener('click', handleClickOutside);

            if (pageHistory?.filter != undefined || pageHistory?.search_key != undefined) {
                searchHandler(pageHistory.page_number);
            } else {
                pageHistory.page_number == undefined ? getCustomers(1) : getCustomers(pageHistory.page_number);
            }
        });

        return {
            customers,
            pagination,
            currentPage,
            filterObject,
            permissions,
            columns,
            loading,
            loader,
            exportFile,
            handleCurrentChange,
            generateAvatar,
            searchHandler,
            handleEdit,
            handleDelete,
            handleView,
            getCustomers,
            activeIndex,
            dropdownHandler,
            handleClickOutside,
            handleLoginAs,
            filterOption,
            statusList,
            getCustomerGroups,
            customerGroupList
        };
    }
});
